import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ProfileDetails from './ProfileDetails';
import PostDetails from './Postdetails';
import NewStory from '../Posts/NewStory';
import { useDispatch, useSelector } from 'react-redux';
import SearchBox from './SearchBar/SearchBox';
import ActionList from './ActionBar/ActionList';
import { ACTION_RESET } from '../../constants/postConstants';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import FlagIcon from '@mui/icons-material/Flag';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NewPost from '../Posts/NewPost';
import { postUploadOutline } from './SvgIcons';

const Header = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [profileToggle, setProfileToggle] = useState(false);
  const [postToggle, setPostToggle] = useState(false);
  const [actionToggle, setActionToggle] = useState(false);
  const [newPost, setNewPost] = useState(false);
  const [newStory, setNewStory] = useState(false);

  const location = useLocation();
  const [onHome, setOnHome] = useState(false);
  const [onModeration, setOnModeration] = useState(false);
  const [onAnalytics, setOnAnalytics] = useState(false);


  useEffect(() => {
    setOnHome(location.pathname === '/');
    setOnModeration(location.pathname === '/moderation');
    setOnAnalytics(location.pathname === '/analytics');
  }, [location]);

  useEffect(() => {
    dispatch({ type: ACTION_RESET });
  }, [user]);

  const handleLogoClick = () => {
    if (onHome) {
      window.location.reload();
    }
  };

  return (<nav className='fixed top-0 w-full border-b bg-white z-10'>
    {/* <!-- navbar container --> */}
    <div
      className='flex flex-row justify-between items-center py-2 px-3.5 sm:w-full sm:py-2 sm:px-4 md:w-full md:py-2 md:px-6 xl:w-4/6 xl:py-3 xl:px-8 mx-auto'>
      {/* <!-- logo --> */}
      <Link className='hidden sm:flex max-w-[90px]' to='/'>
        <img
          draggable='false'
          className='max-w-[90px] h-full object-contain'
          src={require('../../assets/images/instaclone2.png')}
          alt='instaclone-logo'
          onClick={handleLogoClick}
        />
      </Link>

      <SearchBox />

      {/* <!-- icons container  --> */}
      <div className='flex items-center space-x-6 relative'>
        <Link to='/'>
          {profileToggle || !onHome ? <HomeOutlinedIcon /> : <HomeIcon />}
        </Link>
        <div
          onClick={() => setPostToggle(!postToggle)}
          className='cursor-pointer'
        >
          {postUploadOutline}
          {postToggle && (<PostDetails
            setNewPost={setNewPost}
            setPostToggle={setPostToggle}
            setNewStory={setNewStory}
          />)}
        </div>
        <div
          onClick={() => {
            setActionToggle(!actionToggle);
          }}
          className='cursor-pointer'
        >
          {actionToggle ? <FavoriteOutlinedIcon /> : <FavoriteBorderOutlinedIcon />}
          {actionToggle && <ActionList setActionToggle={setActionToggle} />}
        </div>
        <Link to='/analytics'>{onAnalytics ? <AnalyticsIcon /> : <AnalyticsOutlinedIcon />}</Link>
        {user.report_view && <Link to={'/moderation'}>{onModeration ? <FlagIcon /> : <FlagOutlinedIcon />}</Link>}
        <div
          onClick={() => setProfileToggle(!profileToggle)}
          className={`cursor-pointer`}
        >
          <img
            draggable='false'
            loading='lazy'
            className='w-7 h-7 rounded-full object-cover'
            src={user.profile?.picture ? `data:image/jpeg;base64,${user.profile.picture}` : require('../../assets/images/empty_profile.png')}
            alt=''
          />
          {profileToggle && (<ProfileDetails setProfileToggle={setProfileToggle} />)}
        </div>
      </div>

      <NewPost newPost={newPost} setNewPost={setNewPost} />
      <NewStory newPost={newStory} setNewPost={setNewStory} />
    </div>
  </nav>);
};

export default Header;
