import React, { useEffect, useState } from 'react';
import MetaData from '../../Layouts/MetaData';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateSettings } from '../../../actions/userAction';

const NewsfeedAlgorithm = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const newsfeedAlgorithms = [
    { name: t('newsfeed_algorithm_1'), id: 1, value: 'ALGORITHM_1' },
    { name: t('newsfeed_algorithm_2'), id: 2, value: 'ALGORITHM_2' },
    { name: t('newsfeed_algorithm_3'), id: 3, value: 'ALGORITHM_3' },
  ];

  const [newsfeedAlgorithm, setNewsfeedAlgorithm] = useState(
    newsfeedAlgorithms[0].value,
  );
  const { user } = useSelector((state) => state.user);
  const { loading, error } = useSelector((state) => state.settings);

  useEffect(() => {
    if (user.settings) {
      setNewsfeedAlgorithm(user.settings.newsfeed_algorithm);
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      user: user.id,
      newsfeed_algorithm: newsfeedAlgorithm,
    };
    dispatch(updateSettings(data, user));
  };

  return (
    <>
      <MetaData title={t('change_newsfeed_algorithm') + ' • Instaclone'} />
      <form
        onSubmit={handleSubmit}
        encType='multipart/form-data'
        className='flex flex-col gap-4 py-4 px-4 sm:py-10 sm:px-24 sm:w-3/4'
      >
        <div className='flex w-full gap-8 text-right items-center'>
          <span className='w-1/4 font-semibold'>
            {t('newsfeed_algorithm')}:
          </span>
          <select
            value={newsfeedAlgorithm}
            className='border rounded p-1 w-3/4'
            onChange={(e) => setNewsfeedAlgorithm(e.target.value)}
          >
            {newsfeedAlgorithms.map((e) => (
              <option key={e.id} value={e.value}>
                {t(e.name)}
              </option>
            ))}
          </select>
        </div>
        <button
          type='submit'
          disabled={loading}
          className='bg-primary-blue font-medium rounded text-white py-2 w-40 mx-auto text-sm'
        >
          {t('save')}
        </button>
      </form>
    </>
  );
};

export default NewsfeedAlgorithm;
