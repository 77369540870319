import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
    allUsersReducer,
    followUserReducer,
    forgotPasswordReducer,
    profileReducer,
    settingsReducer,
    userDetailsReducer,
    userReducer,
} from './reducers/userReducer';
import {
    actionsReducer,
    allPostsReducer,
    deleteCommentReducer,
    deletePostReducer,
    deleteTagReducer, editPostReducer,
    likePostReducer,
    newAdvertisementReducer,
    newCommentReducer,
    newPostReducer,
    newsfeedReducer,
    savePostReducer,
} from './reducers/postReducer';
import { getStoryReducer, newStoryReducer, storyViewReducer } from './reducers/storyReducer';
import { analyticsReducer } from './reducers/analyticsReducer';
import { moderationReducer, reportPostReducer, restrictUserReducer } from './reducers/moderationReducer';

const reducer = combineReducers({
    user: userReducer,
    forgotPassword: forgotPasswordReducer,
    newPost: newPostReducer,
    newAdvertisement: newAdvertisementReducer,
    newStory: newStoryReducer,
    userDetails: userDetailsReducer,
    allUsers: allUsersReducer,
    newsfeed: newsfeedReducer,
    likePost: likePostReducer,
    followUser: followUserReducer,
    newComment: newCommentReducer,
    savePost: savePostReducer,
    deletePost: deletePostReducer,
    profile: profileReducer,
    settings: settingsReducer,
    allPosts: allPostsReducer,
    getStories: getStoryReducer,
    storyView: storyViewReducer,
    analytics: analyticsReducer,
    deleteTag: deleteTagReducer,
    actions: actionsReducer,
    postReports: reportPostReducer,
    moderation: moderationReducer,
    restrictUser: restrictUserReducer,
    deleteComment: deleteCommentReducer,
    editPost: editPostReducer,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
