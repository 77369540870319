export const POST_REPORT_REQUEST = 'POST_REPORT_REQUEST';
export const POST_REPORT_SUCCESS = 'POST_REPORT_SUCCESS';
export const POST_REPORT_RESET = 'POST_REPORT_RESET';
export const POST_REPORT_FAIL = 'POST_REPORT_FAIL';
export const REPORT_IGNORE_SUCCESS = 'REPORT_IGNORE_SUCCESS';
export const REPORT_IGNORE_FAIL = 'REPORT_IGNORE_FAIL';
export const REPORT_IGNORE_RESET = 'REPORT_IGNORE_RESET';
export const USER_RESTRICT_CREATE_SUCCESS = 'USER_RESTRICT_CREATE_SUCCESS';
export const USER_RESTRICT_CREATE_FAIL = 'USER_RESTRICT_CREATE_FAIL';
export const USER_RESTRICT_CREATE_RESET = 'USER_RESTRICT_CREATE_RESET';
export const USER_RESTRICT_DELETE_SUCCESS = 'USER_RESTRICT_DELETE_SUCCESS';
export const USER_RESTRICT_DELETE_FAIL = 'USER_RESTRICT_DELETE_FAIL';
export const USER_RESTRICT_DELETE_RESET = 'USER_RESTRICT_DELETE_RESET';
export const USER_RESTRICT_REQUEST = 'USER_RESTRICT_REQUEST';
export const USER_RESTRICT_SUCCESS = 'USER_RESTRICT_SUCCESS';
export const USER_RESTRICT_FAIL = 'USER_RESTRICT_FAIL';
export const USER_RESTRICT_RESET = 'USER_RESTRICT_RESET';


export const CLEAR_ERRORS = 'CLEAR_ERRORS';
