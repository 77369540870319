export const searchIcon = (
  <svg
    aria-label='Search'
    color='#8e8e8e'
    fill='#8e8e8e'
    height='16'
    role='img'
    viewBox='0 0 24 24'
    width='16'
  >
    <path
      d='M19 10.5A8.5 8.5 0 1110.5 2a8.5 8.5 0 018.5 8.5z'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    ></path>
    <line
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      x1='16.511'
      x2='22'
      y1='16.511'
      y2='22'
    ></line>
  </svg>
);

export const homeOutline = (
  <svg
    aria-label='Home'
    color='#262626'
    fill='#262626'
    height='24'
    role='img'
    viewBox='0 0 24 24'
    width='24'
  >
    <path
      d='M9.005 16.545a2.997 2.997 0 012.997-2.997h0A2.997 2.997 0 0115 16.545V22h7V11.543L12 2 2 11.543V22h7.005z'
      fill='none'
      stroke='currentColor'
      strokeLinejoin='round'
      strokeWidth='2'
    ></path>
  </svg>
);
export const homeFill = (
  <svg
    aria-label='Home'
    color='#262626'
    fill='#262626'
    height='24'
    role='img'
    viewBox='0 0 24 24'
    width='24'
  >
    <path
      d='M22 23h-6.001a1 1 0 01-1-1v-5.455a2.997 2.997 0 10-5.993 0V22a1 1 0 01-1 1H2a1 1 0 01-1-1V11.543a1.002 1.002 0 01.31-.724l10-9.543a1.001 1.001 0 011.38 0l10 9.543a1.002 1.002 0 01.31.724V22a1 1 0 01-1 1z'></path>
  </svg>
);

export const messageOutline = (
  <svg
    aria-label='Messenger'
    color='#262626'
    fill='#262626'
    height='24'
    role='img'
    viewBox='0 0 24 24'
    width='24'
  >
    <path
      d='M12.003 2.001a9.705 9.705 0 110 19.4 10.876 10.876 0 01-2.895-.384.798.798 0 00-.533.04l-1.984.876a.801.801 0 01-1.123-.708l-.054-1.78a.806.806 0 00-.27-.569 9.49 9.49 0 01-3.14-7.175 9.65 9.65 0 0110-9.7z'
      fill='none'
      stroke='currentColor'
      strokeMiterlimit='10'
      strokeWidth='1.739'
    ></path>
    <path
      d='M17.79 10.132a.659.659 0 00-.962-.873l-2.556 2.05a.63.63 0 01-.758.002L11.06 9.47a1.576 1.576 0 00-2.277.42l-2.567 3.98a.659.659 0 00.961.875l2.556-2.049a.63.63 0 01.759-.002l2.452 1.84a1.576 1.576 0 002.278-.42z'
      fillRule='evenodd'
    ></path>
  </svg>
);
export const messageFill = (
  <svg
    aria-label='Messenger'
    color='#262626'
    fill='#262626'
    height='24'
    role='img'
    viewBox='0 0 24 24'
    width='24'
  >
    <path
      d='M12.003 1.131a10.487 10.487 0 00-10.87 10.57 10.194 10.194 0 003.412 7.771l.054 1.78a1.67 1.67 0 002.342 1.476l1.935-.872a11.767 11.767 0 003.127.416 10.488 10.488 0 0010.87-10.57 10.487 10.487 0 00-10.87-10.57zm5.786 9.001l-2.566 3.983a1.577 1.577 0 01-2.278.42l-2.452-1.84a.63.63 0 00-.759.002l-2.556 2.049a.659.659 0 01-.96-.874L8.783 9.89a1.576 1.576 0 012.277-.42l2.453 1.84a.63.63 0 00.758-.003l2.556-2.05a.659.659 0 01.961.874z'></path>
  </svg>
);

export const exploreOutline = (
  <svg
    aria-label='Find People'
    color='#262626'
    fill='#262626'
    height='24'
    role='img'
    viewBox='0 0 24 24'
    width='24'
  >
    <polygon
      fill='none'
      points='13.941 13.953 7.581 16.424 10.06 10.056 16.42 7.585 13.941 13.953'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    ></polygon>
    <polygon
      fillRule='evenodd'
      points='10.06 10.056 13.949 13.945 7.581 16.424 10.06 10.056'
    ></polygon>
    <circle
      cx='12.001'
      cy='12.005'
      fill='none'
      r='10.5'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    ></circle>
  </svg>
);

export const likeFill = (
  <svg
    aria-label='Unlike'
    color='#ed4956'
    fill='#ed4956'
    height='24'
    role='img'
    viewBox='0 0 48 48'
    width='24'
  >
    <path
      d='M34.6 3.1c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5s1.1-.2 1.6-.5c1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z'></path>
  </svg>
);

export const likeOutline = (
  <svg
    aria-label='Activity Feed'
    color='#262626'
    fill='#262626'
    height='24'
    role='img'
    viewBox='0 0 24 24'
    width='24'
  >
    <path
      d='M16.792 3.904A4.989 4.989 0 0121.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 014.708-5.218 4.21 4.21 0 013.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 013.679-1.938m0-2a6.04 6.04 0 00-4.797 2.127 6.052 6.052 0 00-4.787-2.127A6.985 6.985 0 00.5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 003.518 3.018 2 2 0 002.174 0 45.263 45.263 0 003.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 00-6.708-7.218z'></path>
  </svg>
);

export const postUploadOutline = (
  <svg
    aria-label='New Post'
    color='#262626'
    fill='#262626'
    height='24'
    role='img'
    viewBox='0 0 24 24'
    width='24'
  >
    <path
      d='M2 12v3.45c0 2.849.698 4.005 1.606 4.944.94.909 2.098 1.608 4.946 1.608h6.896c2.848 0 4.006-.7 4.946-1.608C21.302 19.455 22 18.3 22 15.45V8.552c0-2.849-.698-4.006-1.606-4.945C19.454 2.7 18.296 2 15.448 2H8.552c-2.848 0-4.006.699-4.946 1.607C2.698 4.547 2 5.703 2 8.552z'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    ></path>
    <line
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      x1='6.545'
      x2='17.455'
      y1='12.001'
      y2='12.001'
    ></line>
    <line
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      x1='12.003'
      x2='12.003'
      y1='6.545'
      y2='17.455'
    ></line>
  </svg>
);
export const storyUploadOutline = (
  <svg
    aria-label='New Story'
    color='#262626'
    fill='#262626'
    height='24'
    role='img'
    viewBox='0 0 24 24'
    width='24'
  >
    <path
      d='M2 12v3.45c0 2.849.698 4.005 1.606 4.944.94.909 2.098 1.608 4.946 1.608h6.896c2.848 0 4.006-.7 4.946-1.608C21.302 19.455 22 18.3 22 15.45V8.552c0-2.849-.698-4.006-1.606-4.945C19.454 2.7 18.296 2 15.448 2H8.552c-2.848 0-4.006.699-4.946 1.607C2.698 4.547 2 5.703 2 8.552z'
      strokeDasharray='5,5'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    ></path>
    <line
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      x1='6.545'
      x2='17.455'
      y1='12.001'
      y2='12.001'
    ></line>
    <line
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      x1='12.003'
      x2='12.003'
      y1='6.545'
      y2='17.455'
    ></line>
  </svg>
);

export const profileIcon = (
  <svg
    aria-label='Profile'
    color='#262626'
    fill='#262626'
    height='16'
    role='img'
    viewBox='0 0 24 24'
    width='16'
  >
    <circle
      cx='12.004'
      cy='12.004'
      fill='none'
      r='10.5'
      stroke='currentColor'
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2'
    ></circle>
    <path
      d='M18.793 20.014a6.08 6.08 0 00-1.778-2.447 3.991 3.991 0 00-2.386-.791H9.38a3.994 3.994 0 00-2.386.791 6.09 6.09 0 00-1.779 2.447'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2'
    ></path>
    <circle
      cx='12.006'
      cy='9.718'
      fill='none'
      r='4.109'
      stroke='currentColor'
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2'
    ></circle>
  </svg>
);
export const savedIcon = (
  <svg
    aria-label='Saved'
    color='#262626'
    fill='#262626'
    height='16'
    role='img'
    viewBox='0 0 24 24'
    width='16'
  >
    <polygon
      fill='none'
      points='20 21 12 13.44 4 21 4 3 20 3 20 21'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    ></polygon>
  </svg>
);
export const settingsIcon = (
  <svg
    aria-label='Settings'
    color='#262626'
    fill='#262626'
    height='16'
    role='img'
    viewBox='0 0 24 24'
    width='16'
  >
    <circle
      cx='12'
      cy='12'
      fill='none'
      r='8.635'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    ></circle>
    <path
      d='M14.232 3.656a1.269 1.269 0 01-.796-.66L12.93 2h-1.86l-.505.996a1.269 1.269 0 01-.796.66m-.001 16.688a1.269 1.269 0 01.796.66l.505.996h1.862l.505-.996a1.269 1.269 0 01.796-.66M3.656 9.768a1.269 1.269 0 01-.66.796L2 11.07v1.862l.996.505a1.269 1.269 0 01.66.796m16.688-.001a1.269 1.269 0 01.66-.796L22 12.93v-1.86l-.996-.505a1.269 1.269 0 01-.66-.796M7.678 4.522a1.269 1.269 0 01-1.03.096l-1.06-.348L4.27 5.587l.348 1.062a1.269 1.269 0 01-.096 1.03m11.8 11.799a1.269 1.269 0 011.03-.096l1.06.348 1.318-1.317-.348-1.062a1.269 1.269 0 01.096-1.03m-14.956.001a1.269 1.269 0 01.096 1.03l-.348 1.06 1.317 1.318 1.062-.348a1.269 1.269 0 011.03.096m11.799-11.8a1.269 1.269 0 01-.096-1.03l.348-1.06-1.317-1.318-1.062.348a1.269 1.269 0 01-1.03-.096'
      fill='none'
      stroke='currentColor'
      strokeLinejoin='round'
      strokeWidth='2'
    ></path>
  </svg>
);
export const switchAccountIcon = (
  <svg
    aria-label='Switch Accounts'
    color='#262626'
    fill='#262626'
    height='16'
    role='img'
    viewBox='0 0 24 24'
    width='16'
  >
    <path
      d='M8 8.363a1 1 0 00-1-1H4.31a8.977 8.977 0 0114.054-1.727 1 1 0 101.414-1.414A11.003 11.003 0 003 5.672V3.363a1 1 0 10-2 0v5a1 1 0 001 1h5a1 1 0 001-1zm14 6.274h-5a1 1 0 000 2h2.69a8.977 8.977 0 01-14.054 1.727 1 1 0 00-1.414 1.414A11.004 11.004 0 0021 18.33v2.307a1 1 0 002 0v-5a1 1 0 00-1-1z'></path>
  </svg>
);
export const addStoryIcon = (
  <svg
    aria-label='Add Story'
    color='#262626'
    fill='#262626'
    height='16'
    role='img'
    viewBox='0 0 24 24'
    width='16'
  >
    <circle
      cx='12.004'
      cy='12.004'
      fill='none'
      r='10.5'
      stroke='currentColor'
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2'
    ></circle>
    <path
      d='M104,31.15c2.31,5.65,5.11,11.94,6.52,17.55a51.83,51.83,0,0,1,1.54,15.24,50.9,50.9,0,0,1-3,15,49.87,49.87,0,0,1-7.24,13.23A49.12,49.12,0,0,1,91,102.49h0a54.85,54.85,0,0,1-4.74,3q-2.44,1.38-5,2.49a52,52,0,0,1-11.38,3.56,49.5,49.5,0,0,1-11.67.82L57,112.28l-2.76,10.28,1.89.15A60.44,60.44,0,0,0,71.05,122a63,63,0,0,0,14.51-4.42q2.94-1.3,5.83-2.93c1.87-1.06,3.75-2.25,5.62-3.55A59.59,59.59,0,0,0,119,82.48,61.68,61.68,0,0,0,120.7,46.1c-2-5.77-4.18-13.39-8.31-19.22-2.73-3.86-10.73-1.57-8.35,4.27ZM56,44.74a5.34,5.34,0,1,1,10.67,0V56.1H78a5.34,5.34,0,0,1,0,10.68H66.66V78.14a5.34,5.34,0,1,1-10.67,0V66.78H44.62a5.34,5.34,0,1,1,0-10.68H56V44.74ZM24.25,110.36a59.9,59.9,0,0,0,11.46,7l1.69.79,2.75-10.25L39,107.35a49.27,49.27,0,0,1-8.38-5.28,50.34,50.34,0,0,1-7.25-6.93l-.85-1-9.17,5.29,1.18,1.43a61.27,61.27,0,0,0,9.72,9.48ZM.57,69.53A59.49,59.49,0,0,0,4,82.67l.65,1.72,9.17-5.29-.44-1.24a49.32,49.32,0,0,1-2.4-9.72,48.75,48.75,0,0,1-.37-10l.07-1.29L.41,54.15.24,56A58.82,58.82,0,0,0,.57,69.53ZM8.38,31c-.92,1.55-1.75,3.11-2.51,4.7l-.81,1.7,10.25,2.75L15.89,39c.58-1.13,1.19-2.23,1.83-3.29s1.35-2.13,2.1-3.17a61.6,61.6,0,0,1,3.9-4.93A53.89,53.89,0,0,1,28,23.22l.93-.85-5.31-9.21-1.44,1.25a66.4,66.4,0,0,0-5.74,5.65,72.56,72.56,0,0,0-5.15,6.41c-1.05,1.48-2,3-2.92,4.5ZM50.44.78A54.27,54.27,0,0,0,40.27,3.52l-1.74.65,5.3,9.18,1.22-.43a44.39,44.39,0,0,1,15.71-2.43c1.21,0,2.48.11,3.79.26s2.51.33,3.74.58,2.55.56,3.8.93,2.5.8,3.79,1.3h0a5.24,5.24,0,1,0,3.79-9.77C78.15,3.2,76.59,2.67,75,2.2s-3.12-.84-4.65-1.15S67.28.5,65.76.33,62.63.05,61,0A55.87,55.87,0,0,0,50.44.78Z'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2'
    ></path>
    <circle
      cx='12.006'
      cy='9.718'
      fill='none'
      r='4.109'
      stroke='currentColor'
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2'
    ></circle>
  </svg>
);
