export const GET_STORIES_REQUEST = 'GET_STORIES_REQUEST';
export const GET_STORIES_SUCCESS = 'GET_STORIES_SUCCESS';
export const GET_STORIES_RESET = 'GET_STORIES_RESET';
export const GET_STORIES_FAIL = 'GET_STORIES_FAIL';

export const NEW_STORY_REQUEST = 'NEW_STORY_REQUEST';
export const NEW_STORY_SUCCESS = 'NEW_STORY_SUCCESS';
export const NEW_STORY_RESET = 'NEW_STORY_RESET';
export const NEW_STORY_FAIL = 'NEW_STORY_FAIL';

export const STORY_VIEW_SUCCESS = 'STORY_VIEW_SUCCESS';
export const STORY_VIEW_FAIL = 'STORY_VIEW_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';
