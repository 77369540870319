export const NEW_POST_REQUEST = 'NEW_POST_REQUEST';
export const NEW_POST_SUCCESS = 'NEW_POST_SUCCESS';
export const NEW_POST_RESET = 'NEW_POST_RESET';
export const NEW_POST_FAIL = 'NEW_POST_FAIL';

export const NEW_ADVERTISEMENT_REQUEST = 'NEW_ADVERTISEMENT_REQUEST';
export const NEW_ADVERTISEMENT_SUCCESS = 'NEW_ADVERTISEMENT_SUCCESS';
export const NEW_ADVERTISEMENT_FAIL = 'NEW_ADVERTISEMENT_FAIL';

export const NEWSFEED_REQUEST = 'NEWSFEED_REQUEST';
export const NEWSFEED_SUCCESS = 'NEWSFEED_SUCCESS';
export const NEWSFEED_RESET = 'NEWSFEED_RESET';
export const NEWSFEED_FAIL = 'NEWSFEED_FAIL';

export const LIKE_UNLIKE_POST_REQUEST = 'LIKE_UNLIKE_POST_REQUEST';
export const LIKE_UNLIKE_POST_SUCCESS = 'LIKE_UNLIKE_POST_SUCCESS';
export const LIKE_UNLIKE_POST_RESET = 'LIKE_UNLIKE_POST_RESET';
export const LIKE_UNLIKE_POST_FAIL = 'LIKE_UNLIKE_POST_FAIL';

export const NEW_COMMENT_REQUEST = 'NEW_COMMENT_REQUEST';
export const NEW_COMMENT_SUCCESS = 'NEW_COMMENT_SUCCESS';
export const NEW_COMMENT_RESET = 'NEW_COMMENT_RESET';
export const NEW_COMMENT_FAIL = 'NEW_COMMENT_FAIL';

export const SAVE_UNSAVE_POST_REQUEST = 'SAVE_UNSAVE_POST_REQUEST';
export const SAVE_UNSAVE_POST_SUCCESS = 'SAVE_UNSAVE_POST_SUCCESS';
export const SAVE_UNSAVE_POST_RESET = 'SAVE_UNSAVE_POST_RESET';
export const SAVE_UNSAVE_POST_FAIL = 'SAVE_UNSAVE_POST_FAIL';

export const DELETE_POST_REQUEST = 'DELETE_POST_REQUEST';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export const DELETE_POST_RESET = 'DELETE_POST_RESET';
export const DELETE_POST_FAIL = 'DELETE_POST_FAIL';

export const ALL_POSTS_REQUEST = 'ALL_POSTS_REQUEST';
export const ALL_POSTS_SUCCESS = 'ALL_POSTS_SUCCESS';
export const ALL_POSTS_RESET = 'ALL_POSTS_RESET';
export const ALL_POSTS_FAIL = 'ALL_POSTS_FAIL';

export const DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAIL = 'DELETE_TAG_FAIL';
export const DELETE_TAG_RESET = 'DELETE_TAG_RESET';

export const ACTION_REQUEST = 'ACTION_REQUEST';
export const ACTION_SUCCESS = 'ACTION_SUCCESS';
export const ACTION_FAIL = 'ACTION_FAIL';
export const ACTION_RESET = 'ACTION_RESET';

export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAIL = 'DELETE_COMMENT_FAIL';
export const DELETE_COMMENT_RESET = 'DELETE_COMMENT_RESET';

export const EDIT_POST_REQUEST = 'EDIT_POST_REQUEST';
export const EDIT_POST_SUCCESS = 'EDIT_POST_SUCCESS';
export const EDIT_POST_FAIL = 'EDIT_POST_FAIL';
export const EDIT_POST_RESET = 'EDIT_POST_RESET';

export const GET_LIKES_REQUEST = 'GET_LIKES_REQUEST';
export const GET_LIKES_SUCCESS = 'GET_LIKES_SUCCESS';
export const GET_LIKES_FAIL = 'GET_LIKES_FAIL';
export const GET_LIKES_RESET = 'GET_LIKES_RESET';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';
